export function getGridStyle(cols: number): string {
  return `repeat(${cols}, minmax(0, 1fr))`;
}

export function hexToRgbaCSS(
  hex: string,
  opacity?: number
): string | undefined {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return undefined;
  const r = parseInt(result[1], 16),
    g = parseInt(result[2], 16),
    b = parseInt(result[3], 16);
  if (opacity === undefined) return `rgb(${r}, ${g}, ${b})`;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// borrowed from https://stackoverflow.com/a/13532993
export function shadeColor(color: `#${string}`, percent: number): `#${string}` {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = (R * (100 + percent)) / 100;
  G = (G * (100 + percent)) / 100;
  B = (B * (100 + percent)) / 100;

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const sR = Math.round(R).toString(16);
  const sG = Math.round(G).toString(16);
  const sB = Math.round(B).toString(16);

  const RR = sR.length === 1 ? '0' + sR : sR;
  const GG = sG.length === 1 ? '0' + sG : sG;
  const BB = sB.length === 1 ? '0' + sB : sB;

  return `#${RR}${GG}${BB}`;
}

export function hexToRgb(hex: string) {
  if (hex[0] !== '#') return [0, 0, 0, 0] as const;

  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  const a = parseInt(hex.substr(7, 2) || 'FF', 16);
  return [r, g, b, a] as const;
}

export function rgbToHsl(r: number, g: number, b: number) {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 1;
  let s;
  const l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}
